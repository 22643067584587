angular.module("aerosApp")
    .factory('chooseLicenseModal', ["$uibModal", function ($uibModal) {
        function open(licenses, availableRoles, selectedRoles, user) {
            var modal = $uibModal.open({

                size: "md",
                resolve: {
                    licenses: function () {
                        return licenses;
                    },
                    availableRoles: function () {
                        return availableRoles;
                    },
                    selectedRoles: function () {
                        return selectedRoles;
                    },
                    user: function () {
                        return user;
                    }
                },
                templateUrl: "/static/templates/project/chooseLicenseModal.html",
                controller: "ChooseLicenseModalCtrl"
            });
            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("ChooseLicenseModalCtrl", ["$scope", "$uibModalInstance", "Notification", "licenses", "availableRoles", "selectedRoles", "user", function ($scope, $uibModalInstance, Notification, licenses, availableRoles, selectedRoles, user) {

        $scope.licenseKeys = licenses;
        var now = new Date().getMilliseconds();
        $scope.notExpiredLicenseKeys = licenses.filter(function(license) {
            return !license.expirationDate || license.expirationDate > now;
        });
        $scope.chosenLicenseKey = "";
        $scope.availableRolesToAssignUser = availableRoles;

        $scope.chooseLicense = function () {
            $uibModalInstance.close($scope.chosenLicenseKey);
        };

        $scope.selectedRoleRank = _.map(_.take(_.sortBy( selectedRoles, function (role) {return role.rank;})),"rank");

        $scope.toggleRole = function (role) {
            user.roles = [role.id];
            $scope.selectedRoleRank = role.rank;
        };
        var first = true;
        $scope.showChecked = function (role) {
        	var isSelected = (role.rank >= $scope.selectedRoleRank);
        	// ensure default selection is passed when no selection action is taken
        	if (first && isSelected) {
        		user.roles = [role.id];
        		first = false;
        	} 
            return isSelected;
        };
    }]);